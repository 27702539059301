import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import { AddIcCallOutlined, PlusOne } from "@mui/icons-material";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";

// it is category List And this View Product

function UserList(props) {
  const [user, setUser] = useState([]);
  const [checked, setChecked] = useState(false);
  const [open, setopen] = useState(false)

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/fetch_users_admin?type=${props.type}`,
        {
          headers,
        }
      );
      setUser(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "email",
      headerName: "email",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {props.title == "Vendor" ? params.row.business_owner_email : params.row.email}
          </>
        )
      }
    },
    props.type == "Vendor" && {
      field: "is_email_verified",
      headerName: "Email Verified",
      flex: 1,
      renderCell: (params) => {
        return (
          <>

            {params.row.is_active ? <span className="bg-success badge text-white">Verified</span> : params.row.is_email_verified ? <span className="bg-success badge text-white">Verified</span> : <span className="bg-warning badge text-white">Pending</span>}
          </>
        )
      }
    },
    { field: "phone", headerName: "Phone", flex: 1 },
    // props.type == "Vendor" && { field: "username", headerName: "Username", flex: 1 },
    {
      field: "image",
      headerName: "image",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`https://twinscart.com:8000/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/userDetail/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },
    props.type == "Vendor" && {
      field: "is_active", headerName: "Is Active", flex: 1, renderCell: (params) => {
        return (
          <>
            <label>
              <Switch onChange={(e) => handleChange(params.row.is_active, params.row._id)} checked={params.row.is_active} />
            </label>
          </>
        )
      }
    },

  ];


  const handleChange = (checked, id) => {
    // console.log("hellocheck", !checked)
    setChecked(!checked);
    setopen(true)
    let requestData = {
      _id: id,
      is_active: !checked
    }
    axios.put(`${BaseUrl}/update_user_admin`, requestData, { headers }).then((res) => {
      console.log(res.data)
      if (res.data.status == "OK") {
        toast.success("Succesffully Update")
        fetchData()
      }
    }).finally(() => {
      setopen(false)
    })
  };



  // const reversedRows = [...user].reverse();
  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>

            {props.type == "Vendor" && <>
              <div className="col-md-12">
                <div className="text-end mb-3">
                  <Link to={'/addvendor'} className="btn bg-primary text-white">Add Vendor</Link>
                </div>
              </div></>}
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">{props.type} List</h4>
                <div className="">
                  <DataGrid
                    rows={user}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...user.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(UserList);
