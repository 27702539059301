import React, { useEffect, useState } from 'react'
import adminLayout from '../hoc/adminLayout'
import { Col, Form, FormGroup } from 'react-bootstrap'
import axios from 'axios'
import { BaseUrl } from '../utils/Token'
import { toast } from 'react-toastify'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from '@mui/material'
import Loader from '../common/Loader'

function Faq() {
    const [question, setquestion] = useState("")
    const [answer, setanswer] = useState("")
    const [category, setcategory] = useState("")
    const [data, setdata] = useState([])
    const [editid, seteditid] = useState("")
    const [open, setopen] = useState(false)

    let token = localStorage.getItem("token")

    const headers = {
        Authorization: `Bearer ${token}`
    }


    const handleEditClick = (item) => {
        setquestion(item.question)
        setanswer(item.answer)
        setcategory(item.category)
        seteditid(item._id)
    };

    const handleClear = () => {
        setanswer("")
        setquestion("")
        setcategory("")
        seteditid("")
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setopen(true)
        let requestdata = {
            question: question,
            answer: answer,
            category: category
        }
        if (editid) {
            requestdata._id = editid
            axios.put(`${BaseUrl}/faqs`, requestdata, { headers }).then((res) => {
                if (res.data.status == "OK") {
                    toast.success("Data Update Successfully")
                    handleClear()
                    handleget()
                    setopen(false)
                }
            })
        } else {
            axios.post(`${BaseUrl}/faqs`, requestdata, { headers }).then((res) => {
                if (res.data.status == "OK") {
                    toast.success("Data submit Successfully")
                    handleClear()
                    handleget()

                    setopen(false)

                }
            })
        }
    }

    const handleget = () => {
        axios.get(`${BaseUrl}/faqs`, { headers }).then((res) => {
            console.log(res.data.data)
            setdata(res.data.data)
        })
    }

    const getRowId = (row) => row._id

    useEffect(() => {
        handleget()
    }, [])



    const columns = [
        { field: "category", headerName: "Category", flex: 1 },
        { field: "question", headerName: "Question", flex: 1 },
        { field: "answer", headerName: "Answer", flex: 1 },
        {
            field: "edit",
            headerName: "Edit",

            width: 300,
            renderCell: (params) => {
                return (
                    <Link to={``}>
                        <IconButton
                            style={{ color: "black" }}
                            aria-label="edit"
                            onClick={() => handleEditClick(params.row)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Link>
                );
            },
        },

    ];


    return (
        <>
            <Loader open={open} />
            <section>
                <div className="container">
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="bg-white box">
                                <h4 className="px-3 py-3">FAQ</h4>
                                <div className="row gy-4 my-3">
                                    <Col md="4">
                                        <FormGroup>
                                            <label htmlFor="" className="fw-bold">
                                                Category
                                            </label>

                                            <select className='form-control form-select' value={category} onChange={(e) => setcategory(e.target.value)}>
                                                <option value="">--Select--</option>
                                                <option value="Orders">Orders</option>
                                                <option value="Profile">Profile</option>
                                                <option value="Payments">Payments</option>
                                                <option value="Refunds">Refunds</option>

                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <label htmlFor="">Question</label>
                                            <input type="text" className='form-control' value={question} onChange={(e) => setquestion(e.target.value)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <label htmlFor="">Answer</label>
                                            <input type="text" className='form-control' value={answer} onChange={(e) => setanswer(e.target.value)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-12">
                                        <button className='btn bg-primary text-white' >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div className="row">
                        <Col md="12">
                            <div className="bg-white  box">
                                <h4 className="px-3 py-3">Blogs List</h4>
                                <div className="">
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        initialState={{
                                            ...data.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </section>
        </>
    )
}

export default adminLayout(Faq)
