import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";

// it is category List And this View Product

function Promolist() {
  const [promo, setPromo] = useState([]);
  const [open, setOpen] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/promos`, {
        headers,
      });
      setPromo(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 300 },
    { field: "code", headerName: "Code", width: 100 },
    { field: "off_type", headerName: "Off Type", width: 100 },
    { field: "max_off", headerName: "Maximum Off", width: 200 },
    {
      field: "off_after_this_value",
      headerName: " Off After this Value",
      width: 100,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      width: 100,
    },
    {
      field: "value",
      headerName: "Value",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 100,
    },
    // {
    //   field: "image",
    //   headerName: "image",
    //   width: 100,
    // },
    {
      field: "file",
      headerName: "image",
      width: 200,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`https://twinscart.com:8000/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 140,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/editpromo/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      width: 140,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link>
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => handledeleteClick(id)}
            >
              <i class="fa-sharp fa-solid fa-trash text-dark"></i>
            </IconButton>
          </Link>
        );
      },
    },
  ];

  const handledeleteClick = async (id) => {
    let data = JSON.stringify({
      _id: id,
      deleted_at: "D",
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: "https://twinscart.com:8000/product",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status == "OK") {
          window.location.reload(true);
          // try {
          //   const res = axios.get("https://twinscart.com:8000/section", {
          //     headers,
          //   });

          //   window.location.reload(true);
          // } catch (error) {
          //   console.error("Error fetching category data:", error);
          // }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Promo List</h4>
                <div className="">
                  <DataGrid
                    rows={promo}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...promo.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(Promolist);
