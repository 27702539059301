import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import Select from "react-select";


function Category() {
  const [name, setName] = useState("");
  const [productthis, setProductThis] = useState("");
  const [flow, setFlow] = useState("");
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const [file, setFile] = useState(null);
  const [category, setCategory] = useState([]);
  const [flowApi, setFlowApi] = useState([]);
  const [commision, setcommision] = useState("")

  const [parent_category, setParent_Category] = useState("");

  const [open, setOpen] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const naivgate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("show_product_after_this", productthis);
    formData.append("flow", flow);
    selectedOptions.forEach((option, index) => formData.append(`related_category[${index}]`, option.value));
    formData.append("parent_category", parent_category);
    formData.append("is_active", true)
    formData.append("file", file);
    formData.append("commision", commision); // Add the file to the form data

    axios
      .post("https://twinscart.com:8000/category", formData, { headers })
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        if (response.data.status == "OK") {
          toast.success("Data Submited Successfully");
          setOpen(false);
          naivgate("/categorylist");
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.error("Data not Submitted");
        setOpen(false);
      });
  };

  const getCategory = () => {
    axios.get("https://twinscart.com:8000/category", { headers }).then((res) => {
      setCategory(res.data.data);
    });
    console.log(category[2]);
    // console.log(category[3].parent_category.name);
  };

  const FlowgetApi = () => {
    axios.get("https://twinscart.com:8000/flows", { headers }).then((res) => {
      setFlowApi(res.data.data);
    });
    console.log(flowApi);
    // console.log(category[3].parent_category.name);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  useEffect(() => {
    getCategory();
    FlowgetApi();
  }, []);
  const options = category.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };




  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3"> Add Category</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name <span className="text-danger">* </span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>

                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option selected>Select a Flow</option>
                            {flowApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Parent Category
                          </label>

                          <select
                            className="form-select"
                            name="parent_category"
                            aria-label="Default select example"
                            value={parent_category}
                            onChange={(e) => setParent_Category(e.target.value)}
                          >
                            <option selected>Select a Parent Category</option>
                            {category.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>


                      <Col md='6'>
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">Select Related Category</label>
                          <Select
                            options={options}
                            isMulti
                            value={selectedOptions}
                            onChange={handleSelectChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            show_product_after_this{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            className="form-select"
                            name="show_product_after_this"
                            required
                            aria-label="Default select example"
                            value={productthis}
                            onChange={(e) => setProductThis(e.target.value)}
                          >
                            <option selected>Open this select menu</option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Commision
                          </label>
                          <input
                            type="text"
                            name="commision"
                            className="form-control"
                            onChange={(e) => setcommision(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Add Category
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(Category);
