import React, { useState } from 'react'

function ImageComponent(props) {

    return (
        <div>
            {props.enlargedSrc && (
                <div
                    className="enlarged-image-container"
                    style={{
                        background:
                            "RGBA(0,0,0,.5) url(" +
                            props.enlargedSrc +
                            ") no-repeat center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        zIndex: "10000",
                        top: "0",
                        left: "0",
                        cursor: "zoom-out",
                    }}
                    onClick={props.closeEnlargedView}
                />
            )}
            <img
                src={props.src}
                className="img-fluid img-enlargable"
                alt=""
                style={{
                    width: "120px",
                    height: "100px",
                    borderRadius: "30px",
                }}
                onClick={() => props.handleImageClick(props.src)}
            />
        </div>

    )
}

export default ImageComponent
